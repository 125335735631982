import { useReducer, Reducer, useState } from 'react';
import Backend from '../../../stores/newBackend';
import { parseAction } from './getAction';

type ActionResult = {
  type: 'results';
  actions: any[];
  total: number;
  currentLink?: string;
  nextParams?: any[];
  previousParams?: any[];
  exportCSV: string;
};

type ReducerAction =
  | { type: 'load'; page: number }
  | ActionResult
  | { type: 'error'; error: any };

interface State {
  loading: boolean;
  error: any;
  actions: any[];
  total: number;
  page: number;
  currentLink?: string;
  nextParams?: any[];
  previousParams?: any[];
  exportCSV: string;
}

const useGetActionsInitialeState = {
  loading: false,
  error: null,
  actions: [],
  total: 0,
  page: 1,
  exportCSV: '',
};

export default function useGetActions(initState: Partial<State>) {
  const [
    {
      loading,
      error,
      actions,
      total,
      nextParams,
      previousParams,
      page,
      currentLink,
      exportCSV,
    },
    dispatch,
  ] = useReducer<Reducer<State, ReducerAction>>(
    useGetActionsReducer,
    initState
      ? {
          ...useGetActionsInitialeState,
          ...initState,
        }
      : useGetActionsInitialeState
  );

  const [fetchBackend] = useState(() => {
    return async (
      token: string,
      criteria?: any,
      link?: string,
      pageNb?: number
    ) => {
      const pageCursor = pageNb ? pageNb : 1;
      dispatch({
        type: 'load',
        page: pageCursor,
      });

      try {
        const results = await Backend.searchAction(token, criteria, link);
        const dispatchData: ActionResult = {
          type: 'results',
          actions: results.data.map(parseAction),
          total: results.meta.page.total,
          currentLink: link,
          exportCSV: `${process.env.REACT_APP_MONIBRAND_BACKEND_V2_API_URL}${results.links.exportCSV}&access_token=${token}`,
        };

        if (results.links.next) {
          dispatchData.nextParams = [
            token,
            criteria,
            results.links.next as string,
            pageCursor + 1,
          ];
        }

        if (results.links.prev) {
          dispatchData.previousParams = [
            token,
            criteria,
            results.links.prev as string,
            pageCursor - 1,
          ];
        }

        dispatch(dispatchData);
      } catch (e) {
        dispatch({ type: 'error', error: e });
      }
    };
  });

  return {
    loading,
    error,
    actions,
    fetch: fetchBackend,
    total,
    nextParams,
    next:
      nextParams !== undefined
        ? () => fetchBackend(...(nextParams as [string, any, string, number]))
        : undefined,
    previous:
      previousParams !== undefined
        ? () =>
            fetchBackend(...(previousParams as [string, any, string, number]))
        : undefined,
    previousParams,
    page,
    currentLink,
    exportCSV,
  };
}

function useGetActionsReducer(state: State, action: ReducerAction): State {
  const newState = { ...state };
  switch (action.type) {
    case 'load':
      newState.loading = true;
      newState.actions = [];
      newState.page = action.page;
      newState.error = undefined;
      newState.nextParams = undefined;
      newState.previousParams = undefined;
      break;
    case 'results':
      newState.loading = false;
      newState.actions = action.actions;
      newState.currentLink = action.currentLink;
      newState.total = action.total;
      newState.nextParams = action.nextParams;
      newState.previousParams = action.previousParams;
      newState.exportCSV = action.exportCSV;
      break;
    case 'error':
      newState.loading = false;
      newState.error = action.error;
      break;
    default:
      throw new Error();
  }

  return newState;
}
