import { observer } from 'mobx-react-lite';
import StopGoStore from './StopGo.store';
import { useContext, useEffect, useState } from 'react';
import { StoreContext } from '../../stores';
import { useTranslation } from 'react-i18next';

function StopGoNews() {
  const { authStore, uiStore } = useContext(StoreContext),
    { currentUser } = authStore,
    [stopGoStore] = useState(
      () => new StopGoStore(currentUser?.id || '', authStore.token)
    ),
    { t } = useTranslation();

  useEffect(() => {
    uiStore.setTitle(t('Stop an Go - News'));

    return () => {
      stopGoStore.stopStore();
    };
    // eslint-disable-next-line
  }, []);

  return (
    <div
      style={{
        position: 'relative',
        width: '100%',
        height: 0,
        paddingTop: '45%',
        paddingBottom: 0,
      }}
    >
      <iframe
        title='Stop Go news'
        loading='lazy'
        style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          top: 0,
          left: 0,
          border: 'none',
          padding: 0,
          margin: 0,
        }}
        src='https://brandanalytic.clicdata.com/v/pub-stop-go'
        allowFullScreen
        allow='fullscreen'
      ></iframe>
    </div>
  );
}

export default observer(StopGoNews);
