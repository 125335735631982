import { stringify } from 'qs';

const BASE_URL = process.env.REACT_APP_MONIBRAND_BACKEND_V2_API_URL as string;

interface SearchActionResponse {
  meta: {
    page: {
      total: number;
      subTotal?: any;
    };
  };
  data: any[];
  links: {
    next?: string;
    prev?: string;
    exportCSV: string;
  };
}

interface KeywordStatResponse {
  meta: {
    page: {
      total: number;
      subTotal?: any;
      totalAds?: number;
      totalViews?: number;
    };
  };
  data: any[];
  links: {
    next?: string;
    prev?: string;
    exportCSV: string;
  };
}

interface AdvertiserStatResponse {
  meta: {
    page: {
      total: number;
      subTotal?: any;
      totalAds?: number;
      totalViews?: number;
    };
  };
  data: any[];
  links: {
    next?: string;
    prev?: string;
    exportCSV: string;
  };
}

const Backend = {
  async searchTag(token: string, criteria?: any) {
    let endpointURL = `${BASE_URL}/advertiserTag/`;
    if (criteria) {
      const searchParams = stringify(criteria);
      endpointURL += `?${searchParams}`;
    }

    const headers = new Headers();
    headers.set('token', token);
    const opts = {
      method: 'GET',
      headers,
    };
    const response = await fetch(endpointURL, opts);
    return response.json();
  },
  async getTagForAction(token: string, actionID: string) {
    let endpointURL = `${BASE_URL}/advertiserTag/action?actionID=${actionID}`;

    const headers = new Headers();
    headers.set('token', token);
    const opts = {
      method: 'GET',
      headers,
    };
    const response: any = await fetch(endpointURL, opts);
    return response.json();
  },
  async loadAction(token: string, actionID: string) {
    const endpointURL = `${BASE_URL}/actions/detail/${actionID}`;

    const headers = new Headers();
    headers.set('token', token);
    const opts = {
      method: 'GET',
      headers,
    };
    const response = await fetch(endpointURL, opts);
    return response.json();
  },
  async searchAction(
    token: string,
    criteria?: any,
    link?: string
  ): Promise<SearchActionResponse> {
    let endpointURL = BASE_URL;

    if (!link) {
      endpointURL += `/actions/search`;
      if (criteria) {
        const searchParams = stringify(criteria);
        endpointURL += `?${searchParams}`;
      }
    } else {
      endpointURL += link;
    }

    const headers = new Headers();
    headers.set('token', token);
    const opts = {
      method: 'GET',
      headers,
    };
    const response = await fetch(endpointURL, opts);
    return response.json();
  },
  async searchAdvertiserFromAction(
    token: string,
    criteria?: any,
    link?: string
  ): Promise<SearchActionResponse> {
    let endpointURL = BASE_URL;

    if (!link) {
      endpointURL += `/actions/searchAll`;
      if (criteria) {
        const searchParams = stringify(criteria);
        endpointURL += `?${searchParams}`;
      }
    } else {
      endpointURL += link;
    }

    const headers = new Headers();
    headers.set('token', token);
    const opts = {
      method: 'GET',
      headers,
    };
    const response = await fetch(endpointURL, opts);
    return response.json();
  },
  async searchAdvertisements(
    token: string,
    criteria?: any,
    link?: string
  ): Promise<any[]> {
    let endpointURL = BASE_URL;

    if (!link) {
      endpointURL += `/advertisement`;
      if (criteria) {
        const searchParams = stringify(criteria);
        endpointURL += `?${searchParams}`;
      }
    } else {
      endpointURL += link;
    }

    const headers = new Headers();
    headers.set('token', token);
    const opts = {
      method: 'GET',
      headers,
    };
    const response = await fetch(endpointURL, opts);
    return response.json();
  },
  async countAdvertisement(
    token: string,
    criteria?: any,
    link?: string
  ): Promise<{ countAdvertisement: number; countShopping: number }> {
    let endpointURL = BASE_URL;

    if (!link) {
      endpointURL += `/advertisement/count`;
      if (criteria) {
        const searchParams = stringify(criteria);
        endpointURL += `?${searchParams}`;
      }
    } else {
      endpointURL += link;
    }

    const headers = new Headers();
    headers.set('token', token);
    const opts = {
      method: 'GET',
      headers,
    };
    const response = await fetch(endpointURL, opts);
    return response.json();
  },
  async searchAdvertisementsByActionID(
    token: string,
    actionID: string,
    criteria?: any
  ): Promise<any[]> {
    let endpointURL = BASE_URL;
    endpointURL += `/advertisement/action/` + actionID;
    if (criteria) {
      const searchParams = stringify(criteria);
      endpointURL += `?${searchParams}`;
    }
    const headers = new Headers();
    headers.set('token', token);
    const opts = {
      method: 'GET',
      headers,
    };
    const response = await fetch(endpointURL, opts);
    return response.json();
  },
  async countAdvertisementByActionID(
    token: string,
    actionID?: string
  ): Promise<{ countAdvertisement: number; countShopping: number }> {
    let endpointURL = BASE_URL;
    endpointURL += `/advertisement/action/` + actionID + `/count`;
    const headers = new Headers();
    headers.set('token', token);
    const opts = {
      method: 'GET',
      headers,
    };
    const response = await fetch(endpointURL, opts);
    return response.json();
  },
  async loadActionsTodo(
    token: string,
    criteria?: any,
    link?: string
  ): Promise<SearchActionResponse> {
    let endpointURL = BASE_URL;

    if (!link) {
      endpointURL += `/actions/todo`;
      if (criteria) {
        const searchParams = stringify(criteria);
        endpointURL += `?${searchParams}`;
      }
    } else {
      endpointURL += link;
    }

    const headers = new Headers();
    headers.set('token', token);
    const opts = {
      method: 'GET',
      headers,
    };
    const response = await fetch(endpointURL, opts);
    return response.json();
  },
  async markAsRequested(
    token: string,
    actionID: string | string[]
  ): Promise<any> {
    const endpointURL = `${BASE_URL}/actions/requested`;
    const headers = new Headers();
    headers.set('token', token);
    headers.set('Content-Type', 'application/json');
    const body = {
      actionID,
    };

    const opts = {
      method: 'POST',
      headers,
      body: JSON.stringify(body),
    };

    const response = await fetch(endpointURL, opts);
    return response.json();
  },
  async markAsInprogress(
    token: string,
    actionID: string | string[],
    comments: string,
    manual: boolean
  ): Promise<any> {
    const endpointURL = `${BASE_URL}/actions/inprogress`;
    const headers = new Headers();
    headers.set('token', token);
    headers.set('Content-Type', 'application/json');
    const body = {
      actionID,
      comments,
      manual,
    };

    const opts = {
      method: 'POST',
      headers,
      body: JSON.stringify(body),
    };

    const response = await fetch(endpointURL, opts);
    return response.json();
  },
  async markAsIgnored(
    token: string,
    actionID: string | string[]
  ): Promise<any> {
    const searchParams = stringify({ actionID });
    const link = `/actions/ignored?${searchParams}`;
    return Backend.executeLink(token, link);
  },
  async cancelIgnore(token: string, actionID: string | string[]): Promise<any> {
    const searchParams = stringify({ actionID });
    const link = `/actions/cancelIgnore?${searchParams}`;
    return Backend.executeLink(token, link);
  },
  async cancelRequested(
    token: string,
    actionID: string | string[]
  ): Promise<any> {
    const searchParams = stringify({ actionID });
    const link = `/actions/cancelRequested?${searchParams}`;
    return Backend.executeLink(token, link);
  },
  async fetchLink(token: string, link: string): Promise<any> {
    const endpointURL = `${BASE_URL}${link}`;
    const headers = new Headers();
    headers.set('token', token);

    const opts = {
      method: 'GET',
      headers,
    };

    const response = await fetch(endpointURL, opts);
    return response.json();
  },
  async executeLink(token: string, link: string, body?: any): Promise<any> {
    const endpointURL = `${BASE_URL}${link}`;
    const headers = new Headers();
    headers.set('token', token);
    headers.set('Content-Type', 'application/json');

    const opts: RequestInit = {
      method: 'POST',
      headers,
    };

    if (body !== undefined) {
      opts.body = JSON.stringify(body);
    }

    const response = await fetch(endpointURL, opts);
    return response.json();
  },
  async loadKeywordStat(
    token: string,
    criteria?: { [key: string]: unknown },
    link?: string
  ): Promise<KeywordStatResponse> {
    let endpointURL = BASE_URL;

    if (!link) {
      endpointURL += `/keywords/stats`;
      if (criteria) {
        const searchParams = stringify(criteria, {
          serializeDate: (d: Date) => d.toISOString(),
        });
        endpointURL += `?${searchParams}`;
      }
    } else {
      endpointURL += link;
    }

    const headers = new Headers();
    headers.set('token', token);
    const opts = {
      method: 'GET',
      headers,
    };
    const response = await fetch(endpointURL, opts);
    return response.json();
  },
  async loadAdvertiserStat(
    token: string,
    criteria?: { [key: string]: unknown },
    link?: string
  ): Promise<AdvertiserStatResponse> {
    let endpointURL = BASE_URL;

    if (!link) {
      endpointURL += `/advertisers/stats`;
      if (criteria) {
        const searchParams = stringify(criteria, {
          serializeDate: (d: Date) => d.toISOString(),
        });
        endpointURL += `?${searchParams}`;
      }
    } else {
      endpointURL += link;
    }

    const headers = new Headers();
    headers.set('token', token);
    const opts = {
      method: 'GET',
      headers,
    };
    const response = await fetch(endpointURL, opts);
    return response.json();
  },
  async loadResult(token: string, resultID: string) {
    const endpointURL = `${BASE_URL}/result/${resultID}`;
    const headers = new Headers();
    headers.set('token', token);
    headers.set('Content-Type', 'application/json');

    const opts = {
      method: 'GET',
      headers,
    };

    const response = await fetch(endpointURL, opts);
    return response.json();
  },
  async loadGoogleConnect(token: string): Promise<[{ customerID: string }]> {
    const endpointURL = `${BASE_URL}/google`;
    const headers = new Headers();
    headers.set('token', token);
    headers.set('Content-Type', 'application/json');

    const opts = {
      method: 'GET',
      headers,
    };

    const response = await fetch(endpointURL, opts);
    return response.json();
  },
  async loadStopGoBrand(token: string): Promise<[{ customerID: string }]> {
    const endpointURL = `${BASE_URL}/stop-go`;
    const headers = new Headers();
    headers.set('token', token);
    headers.set('Content-Type', 'application/json');

    const opts = {
      method: 'GET',
      headers,
    };

    const response = await fetch(endpointURL, opts);
    return response.json();
  },
  async loadStopGoKeyword(token: string): Promise<[{ customerID: string }]> {
    const endpointURL = `${BASE_URL}/stop-go/keyword`;
    const headers = new Headers();
    headers.set('token', token);
    headers.set('Content-Type', 'application/json');

    const opts = {
      method: 'GET',
      headers,
    };

    const response = await fetch(endpointURL, opts);
    return response.json();
  },
};

export default Backend;
